import {usePagination} from 'hooks/usePagination';
import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './baitbox-listing-components/PageHeader';
import {getPestCompanyBaitBoxesAsync, getPestDetailsWithBaitBoxesAsync} from 'redux/pest-company/thunk';
import {
  cleanPestCompanyBaitBoxes,
  updatePestBaitBoxSearchText,
  updatePestBaitBoxSortingKeyAndOrder,
} from 'redux/pest-company/slice';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {useParams} from 'react-router';
import {pestCompanyDetails, selectPestCompanyData} from 'redux/pest-company/selector';
import useTableCheckBoxList from 'hooks/useTableCheckbox';
import BiatboxList from './baitbox-listing-components/BiatboxList';
import DateRangePickerComponent from '../baitchecker-inventory/logs/date-range-filter/DateRange';
import {Button, Modal} from 'react-bootstrap';
import {getExportData} from '../../features/export/api/selector';
import {downloadExportAsync} from '../../features/export/api/thunk';

const PestCompanyDetails = () => {
  const {id} = useParams();
  const dispatch = useAppDispatch();

  const {baitboxSearchText, baitboxSortingKey, baitboxSortingOrder, pestCompanyBaitBoxes} =
    useAppSelector(selectPestCompanyData);

  const {isLoading} = useAppSelector(getExportData);

  const {name} = useAppSelector(pestCompanyDetails);

  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);

  const {data, syncCode, toggleCheckBox, getSelectedBaitBoxesByStatus, toggleCheckAll, checkAll, markCheckUnCheck} =
    useTableCheckBoxList();

  useEffect(() => {
    syncCode(pestCompanyBaitBoxes, id);
  }, [pestCompanyBaitBoxes]);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const onTextChange = (text: string) => {
    dispatch(updatePestBaitBoxSearchText({text}));
    if (page === 1) {
      dispatch(
        getPestCompanyBaitBoxesAsync({
          pestCompanyId: id,
          pageNumber: 1,
          limit,
          searchField: text,
          sortingField: baitboxSortingKey,
          sortingOrder: baitboxSortingOrder,
        }),
      );
    } else {
      onLimitChange(10);
    }
  };

  const getPestCompanyBaitBoxes = () => {
    dispatch(
      getPestCompanyBaitBoxesAsync({
        pestCompanyId: id,
        pageNumber: page,
        limit,
        searchField: baitboxSearchText,
        sortingField: baitboxSortingKey,
        sortingOrder: baitboxSortingOrder,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getPestCompanyBaitBoxes();
    }
  }, [id, page, limit, baitboxSortingKey, baitboxSortingOrder]);

  useEffect(() => {
    // to get latest active/inactive bait boxes count
    dispatch(getPestDetailsWithBaitBoxesAsync(id));
  }, []);

  const triggerExport = async () => {
    const result = await dispatch(downloadExportAsync({pestCompanyId: id, startDate, endDate}));
    if (downloadExportAsync.fulfilled.match(result)) {
      const blob = new Blob([result.payload.data], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      let filename = `${name}`;
      if (startDate) filename += '-from-' + startDate;
      if (endDate) filename += '-until-' + endDate;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setShowExportModal(false);
      return;
    }
  };

  //cleanup
  useEffect(() => {
    return () => {
      dispatch(cleanPestCompanyBaitBoxes());
      dispatch(updatePestBaitBoxSearchText({text: null}));
      dispatch(
        updatePestBaitBoxSortingKeyAndOrder({
          sortingKeyOrder: {sortingKey: null, sortingOrder: null},
        }),
      );
    };
  }, []);
  return (
    <>
      {
        <PageTitleWrapper>
          <PageHeader textChange={onTextChange} getSelectedBaitBoxesByStatus={getSelectedBaitBoxesByStatus} />
          <div>
            <button type={'button'} className={'btn btn-primary mt-2'} onClick={() => setShowExportModal(true)}>
              Export Data...
            </button>
            <Modal show={showExportModal} size={'lg'}>
              <Modal.Header closeButton onClick={() => setShowExportModal(false)}>
                <Modal.Title>Export Data for {name}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <DateRangePickerComponent
                  onDateFiltersChange={({startDate, endDate}) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                />
                {!startDate && !endDate && (
                  <div className={'alert alert-info mt-4'}>
                    <div className={'fw-bold'}>Hint:</div>
                    Not setting a date range will export the complete data set.
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" disabled={isLoading} onClick={triggerExport}>
                  Export
                  {isLoading && (
                    <div className="spinner-border" role="status" style={{width: 16, height: 16}}>
                      <span className="sr-only" />
                    </div>
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </PageTitleWrapper>
      }

      <BiatboxList
        data={data}
        toggleCheckBox={toggleCheckBox}
        pagination={{page, limit, onPageChange, onLimitChange}}
        toggleCheckAll={toggleCheckAll}
        textChange={onTextChange}
        markCheckUnCheck={markCheckUnCheck}
        isCheckAll={checkAll}
      />
    </>
  );
};

export default PestCompanyDetails;
