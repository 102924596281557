import React from 'react';
import Icon from '@mdi/react';
import {mdiClockOutline, mdiEyeOffOutline} from '@mdi/js';
import ReactTimeAgo from 'react-time-ago';
import {getI18n} from 'react-i18next';

interface Props {
  lastTransmissionDate: Date | null;
}

export const LastSeen: React.FC<Props> = ({lastTransmissionDate}) => {
  return (
    <div className={'h6 cur-pointer'}>
      <div className={'mt-2'}>
        <Icon size={0.9} path={lastTransmissionDate ? mdiClockOutline : mdiEyeOffOutline} className={'me-2'} />
        {lastTransmissionDate ? <ReactTimeAgo date={lastTransmissionDate} locale={getI18n().language} /> : 'unseen'}
      </div>
    </div>
  );
};
