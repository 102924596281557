import {createSlice} from '@reduxjs/toolkit';
import {getZBaseDetailsAsync, getZBaseRawLogsAsync} from './thunk';
import initialState from './state';
import {Datapoint} from '../models/ZBaseApiModels';

export const zBaseDetailSlice = createSlice({
  name: 'zbase',
  initialState: {isLoading: false, isLoadingLogs: false, data: initialState, logs: []},
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getZBaseDetailsAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getZBaseDetailsAsync.rejected, state => {
        state.isLoading = false;
        state.data = undefined;
      })
      .addCase(getZBaseDetailsAsync.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.data = payload.data;
      })
      .addCase(getZBaseRawLogsAsync.pending, state => {
        state.isLoadingLogs = true;
        state.logs = undefined;
      })
      .addCase(getZBaseRawLogsAsync.rejected, state => {
        state.isLoadingLogs = false;
        state.logs = [];
      })
      .addCase(getZBaseRawLogsAsync.fulfilled, (state, {payload}) => {
        state.isLoadingLogs = false;
        state.logs = cleanUpData(payload.data);
      });
  },
});

const cleanUpData = (data: Datapoint[]): Datapoint[] =>
  data.map(datum => {
    const correctedValue = Math.max(0, Math.min(datum.value, 120));
    if (datum.value !== correctedValue) {
      return {...datum, value: correctedValue, originalValue: datum.value, date: new Date(datum.date)};
    }
    return {...datum, value: datum.value, date: new Date(datum.date)};
  });

export default zBaseDetailSlice.reducer;
