import FilterListIcon from '@mui/icons-material/FilterList';
import {debounce, Grid, IconButton, Typography} from '@mui/material';
import {LineChart} from '@mui/x-charts/LineChart';
import BatteryIndicator from 'components/UI/BatteryIndicator';
import LoadingButton from 'components/UI/LoadingButton';
import SignalIndicator from 'components/UI/SignalIndicator';
import SearchBar from 'components/UI/searchbar/SearchBar';
import {DEBOUNCE_TIME} from 'constants/debouce-time';
import {ROUTES} from 'constants/routes';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {HttpError} from 'models/http-interface';
import {ActivityLogsDateFilterAction, ActivityLogsFilterAction, BaiBoxActivityFilter} from 'models/inventory.interface';
import {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {getBaitBoxLogsDetails} from 'redux/baitbox-log-details/selector';
import {setBaitBoxLogDetails} from 'redux/baitbox-log-details/slice';
import {getBaitCheckerList, getBaitCheckerState} from 'redux/inventory/selector';
import {downloadActivityLogsCSVAsync, getActivityLogsFiltersAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getStatusIconText} from 'utils/baitbox-status';
import {createYAxis} from 'utils/chat-data';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import DateRangePickerComponent from '../date-range-filter/DateRange';
import LogsFilterPopup from '../logs-filter-popup/LogsFilterPopup';

const {BAITCHECKER_SEARCH_DELAY} = DEBOUNCE_TIME;

const initialFilterValue = {
  clientCompanies: [],
  pestCompanies: [],
  events: [],
  sectionDetails: null,
  metaData: null,
  logsData: [],
  details: null,
};

type BaitBoxLogsHeaderType = {
  textChange?: (text: string) => void;
  onFiltersChange: (filterAction: ActivityLogsFilterAction) => void;
  onDateFiltersChange: (dateFilterAction: ActivityLogsDateFilterAction) => void;
  setDeviceSettings: (deviceSettings: object) => void;
};

const BaitBoxLogsPageHeader: FC<BaitBoxLogsHeaderType> = ({
  textChange,
  onFiltersChange,
  onDateFiltersChange,
  setDeviceSettings,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const {parseUI} = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const {
    csvDownloadLoading,
    activityLogs,
    logsSearchText,
    logsClientCompaniesFilterValues,
    logsEventsFilterValues,
    logsPestCompaniesFilterValues,
    activityLogsDateRangeFilter,
  } = useAppSelector(getBaitCheckerState);

  const baitboxDetails = useAppSelector(getBaitBoxLogsDetails);
  const inventoryList = useAppSelector(getBaitCheckerList);

  const {isOpen, showDialog, hideDialog} = useDialog(false);
  const handleFilterPopup = () => showDialog(true);
  const closePopup = () => hideDialog();
  const textSearchHandler = (text: string) => textChange(text);
  const [filters, setFilters] = useState<BaiBoxActivityFilter | null>(initialFilterValue);

  const [chartData, setChartData] = useState({x: [], y: []});

  const handleSearch = debounce(textSearchHandler, BAITCHECKER_SEARCH_DELAY);

  /**
   * @description: to refetch the baitbox header details if not available from inventory listing
   */
  useEffect(() => {
    const handleNullBaitboxDetails = () => {
      if (!baitboxDetails) {
        /** find the baitbox metadata from inventory list */
        const baitboxMetadata = inventoryList.find(item => item.uuid === id);
        dispatch(setBaitBoxLogDetails(baitboxMetadata));
      }
    };

    handleNullBaitboxDetails();
  }, [baitboxDetails, inventoryList, id, dispatch]);

  const isFiltersApplied = () => {
    return (
      logsEventsFilterValues.length > 0 ||
      logsPestCompaniesFilterValues.length > 0 ||
      logsClientCompaniesFilterValues.length > 0
    );
  };

  const downloadCSV = async () => {
    try {
      const downloadCSVBody = {
        baitBoxId: id,
        pestCompanyIds: logsPestCompaniesFilterValues,
        clientCompanyIds: logsClientCompaniesFilterValues,
        eventIds: logsEventsFilterValues,
        searchField: logsSearchText,
      };
      const csvRes = await dispatch(downloadActivityLogsCSVAsync(downloadCSVBody)).unwrap();

      const downloadUrl = process.env.REACT_APP_BACKEND + csvRes.data.path;

      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute('download', 'activity-logs.csv');
      downloadLink.style.display = 'none';

      // Append the anchor to the body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Clean up - remove the anchor after download
      document.body.removeChild(downloadLink);
      showSuccess(csvRes.message);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  const getFilters = async () => {
    const filters = await dispatch(getActivityLogsFiltersAsync(id)).unwrap();

    setDeviceSettings(filters.data.details.deviceSettingsResponse);

    /** to fetch graph data */
    const yAxis = createYAxis();
    const xAxis = (filters.data.logsData || []).map(log => log.weight);

    setChartData({
      x: JSON.parse(JSON.stringify(xAxis)).reverse(),
      y: yAxis,
    });
    setFilters(filters.data);
  };

  const {metaData, sectionDetails, details} = filters;

  useEffect(() => {
    getFilters();
  }, []);

  return details && baitboxDetails ? (
    <>
      <h1 className="page-title mp-0">{details.slug}</h1>
      <Grid container justifyContent="space-between" alignItems="flex-start" className="detail-header">
        <Grid item className="logs-first-col">
          <div className="bait-checker-row-item">
            <Typography variant="h3" component="h3" gutterBottom>
              {parseUI('SIM_ID')}
            </Typography>
            <div className="inner-row-value">
              <Typography variant="h5" component="h5" gutterBottom>
                {details.iccNumber}
              </Typography>
            </div>
          </div>

          <div className="bait-checker-row-item">
            <div className="inner-row-value">
              <div className="pR-60">
                <Typography variant="h5" component="h5" gutterBottom>
                  {parseUI(baitboxDetails?.isActive ? 'ACTIVE' : 'IN_ACTIVE')}
                </Typography>
              </div>
              {metaData && sectionDetails && (
                <div className="bait-checker-row-item mb-0">
                  <div className="inner-row-value">
                    <ul className="baitbox-icon-info">
                      <li>
                        <img
                          src={getStatusIconText(metaData.status, 1, false).icon}
                          width={24}
                          height={24}
                          alt="status"
                        />
                      </li>
                      <li>
                        <img src="/static/images/baitWeight.png" width={20} /> <span>{metaData.currentWeight}g</span>
                      </li>

                      <li>
                        <BatteryIndicator value={metaData.batteryLevel} /> <span>{metaData.batteryLevel}%</span>
                      </li>
                      <li>
                        <SignalIndicator signalValue={metaData.networkStrength} />
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="filter-search-container">
            {(activityLogsDateRangeFilter?.startDate ||
              activityLogsDateRangeFilter?.endDate ||
              activityLogs.length > 0) && <DateRangePickerComponent onDateFiltersChange={onDateFiltersChange} />}
            {activityLogs.length > 0 && (
              <LoadingButton
                type="button"
                label="DOWNLOAD_CSV"
                loader={csvDownloadLoading}
                className="loading-button"
                onClick={downloadCSV}
              />
            )}
          </div>
          <div className="filter-search-container mt-10">
            {(activityLogs.length > 0 || logsSearchText || isFiltersApplied()) && (
              <>
                <SearchBar onInputChange={handleSearch} textChange={textChange} />
              </>
            )}

            <div className="filter-comment-buttons mt-10">
              {(activityLogs.length > 0 || logsSearchText || isFiltersApplied()) && (
                <div className="filter-outer">
                  <IconButton onClick={() => handleFilterPopup()} aria-label="View" className="baitbox_action_icons">
                    <FilterListIcon />
                    {parseUI('FILTERS')}
                  </IconButton>
                </div>
              )}
              <div className="filter-outer">
                <IconButton
                  onClick={() =>
                    navigate(
                      // eslint-disable-next-line max-len
                      `/${ROUTES.DASHBOARD}/${ROUTES.LIST_BAIT_CHECKER}/${ROUTES.BAIT_BOX_COMMENTS}/${ROUTES.ADD_COMMENT}`,
                    )
                  }
                  aria-label="View"
                  className="baitbox_action_icons">
                  {parseUI('ADD_COMMENTS')}
                </IconButton>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item>
          <Grid className="pest-address logs-center-item">
            <Grid item className="bait-header cus-info">
              <div>
                <h5>{parseUI('PEST_COMPANY_NAME')}</h5>
                <span>{baitboxDetails?.pestCompanyName ? baitboxDetails?.pestCompanyName : '- -'}</span>
              </div>
              <div>
                <h5>{parseUI('CLIENT_COMPANY_NAME')}</h5>
                <span className="street-address">
                  {baitboxDetails?.clientCompanyName ? baitboxDetails?.clientCompanyName : '- -'}
                </span>
              </div>
              {metaData && sectionDetails ? (
                <div>
                  <h5>Section Details</h5>
                  <div>
                    <span>Section Name : {sectionDetails.name}</span>
                  </div>
                  <div>
                    <span>
                      {sectionDetails.parent ? <span>Parent Section Name : {sectionDetails.parent.name}</span> : null}
                    </span>
                  </div>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <div className="chart-outer">
          <div className="day-bait">{parseUI('BAIT_WEIGHT_DAYS')}</div>
          <LineChart
            className="line-chart-outer"
            xAxis={[
              {
                data: chartData.y,
                hideTooltip: true,
                scaleType: 'point',
              },
            ]}
            series={[
              {
                data: chartData.x,
                valueFormatter: value => `${value} g`,
                type: 'line',
                color: '#0f9198',
              },
            ]}
            width={500}
            height={200}
            margin={{left: 30, right: 30, top: 30, bottom: 30}}
            sx={{
              '.MuiLineElement-root': {
                stroke: '#0f9198',
                strokeWidth: 2,
              },
              '.MuiMarkElement-root': {
                scale: '0.5',
                fill: '#0f9198',
                strokeWidth: 2,
              },
            }}
          />
          <div className="day-heading">{parseUI('DAYS')}</div>
        </div>
      </Grid>
      <LogsFilterPopup open={isOpen} onClose={closePopup} onFiltersChange={onFiltersChange} data={filters} />
    </>
  ) : null;
};

export default BaitBoxLogsPageHeader;
