import {createSlice} from '@reduxjs/toolkit';
import {downloadExportAsync} from './thunk';

export const exportDataSlice = createSlice({
  name: 'export',
  initialState: {isLoading: false, exportData: null},
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(downloadExportAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(downloadExportAsync.rejected, state => {
        state.isLoading = false;
        state.exportData = undefined;
      })
      .addCase(downloadExportAsync.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.exportData = new Blob([payload.data], {type: 'text/csv'});
      });
  },
});

export default exportDataSlice.reducer;
