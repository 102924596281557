import axiosInstance from '../../../api/api-service';
import {HttpResponse} from '../../../models/http-interface';
import {ZBaseDetailsRequestParams} from '../models/ZBaseApiModels';

export const getZBaseDetails = (params: ZBaseDetailsRequestParams) => {
  return axiosInstance.get<HttpResponse<any>>('/zbases', {params});
};

export const getZBaseRawLogs = (params: ZBaseDetailsRequestParams) => {
  return axiosInstance.get<HttpResponse<any>>('/zbases/logs', {params});
};
