import ListHeader from 'components/UI/list/ListHeader';
import BaitcheckerList from 'components/baitchecker-inventory/list/BaitcheckerList';
import {ROUTES} from 'constants/routes';
import {usePagination} from 'hooks/usePagination';
import {useCallback, useEffect, useState} from 'react';
import {syncCode} from 'redux/checkBox/slice';
import {getBaitCheckerList, getBaitCheckerState} from 'redux/inventory/selector';
import {
  clearSelectedBaitCheckers,
  updateBaitCheckerSearchText,
  updateBaitCheckerSortingKeyOrder,
} from 'redux/inventory/slice';
import {getBaitCheckerAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {DetailPanel} from '../features/zbases/ui/shared/DetailPanel';
import {mdiBatteryOutline, mdiClockOutline, mdiWeightGram} from '@mdi/js';
import Icon from '@mdi/react';
import ReactTimeAgo from 'react-time-ago';

const BaitCheckerInventory = () => {
  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 50);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string | null>(null);
  const {sortingKey, sortingOrder} = useAppSelector(getBaitCheckerState);
  const inventoryList = useAppSelector(getBaitCheckerList);

  const onTextChange = useCallback(
    (text: string) => {
      setSearchText(text);
      if (text && text.length < 3 && text.length > 0) {
        return;
      }
      dispatch(updateBaitCheckerSearchText({text}));

      if (page === 1) {
        dispatch(
          getBaitCheckerAsync({
            pageNumber: 1,
            limit,
            searchField: text,
            sortingField: sortingKey,
            sortingOrder,
          }),
        );
      } else {
        onLimitChange(10);
      }
    },
    [searchText, inventoryList],
  );

  // Retrieves list of all zbases
  const getBaitChecker = () => {
    dispatch(
      getBaitCheckerAsync({
        pageNumber: page,
        limit,
        searchField: searchText,
        sortingField: sortingKey,
        sortingOrder,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getBaitChecker();
    }
  }, [page, limit, sortingKey, sortingOrder]);

  useEffect(() => {
    //cleanup the state when component unmount
    return () => {
      dispatch(updateBaitCheckerSearchText({text: null}));
      dispatch(
        updateBaitCheckerSortingKeyOrder({
          sortingKeyOrder: {sortingKey: null, sortingOrder: null},
        }),
      );
      dispatch(clearSelectedBaitCheckers());
    };
  }, []);

  useEffect(() => {
    dispatch(syncCode(inventoryList));
  }, [inventoryList]);

  const issueDevices = [
    {slug: 'ABC-123', battery: 28, weight: -10},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30},
    {slug: 'ABC-123', battery: 18, weight: -30002.21},
  ];

  return (
    <>
      <ListHeader
        label="ADD_BAIT_CHECKER"
        url={ROUTES.ADD_BAIT_CHECKER}
        title="BAITCHECKER"
        textChange={onTextChange}
      />
      {/* This concept is hidden for now*/}
      <div className={'container-fluid px-5 py-5 d-none'}>
        <DetailPanel heading={'Low Battery'}>
          <div className={'bg-white d-flex'}>
            {issueDevices.map(d => {
              return (
                <div
                  className={
                    'rounded-4 border border-1 border-secondary-subtle  border-opacity-25' +
                    ' shadow-sm p-3 hoverable cur-pointer me-3'
                  }>
                  <div className={'bold-heading'}>{d.slug}</div>
                  <div>
                    <Icon path={mdiBatteryOutline} className={'text-danger'} size={1} /> {d.battery} %
                  </div>
                  <div>
                    <Icon path={mdiWeightGram} className={'text-danger'} size={1} /> {d.weight} %
                  </div>
                </div>
              );
            })}
          </div>
        </DetailPanel>
        <DetailPanel heading={'Latest Incidents'}>
          <div className={'bg-white d-flex flex-row flex-wrap'}>
            {issueDevices.map(d => {
              return (
                <div
                  className={
                    'rounded-4 border border-1 border-secondary-subtle  border-opacity-25' +
                    ' shadow-sm p-3 hoverable cur-pointer me-3 mb-3'
                  }
                  style={{minWidth: 200}}>
                  <div className={'bold-heading'}>{d.slug}</div>
                  <div>
                    <Icon path={mdiClockOutline} className={'text-secondary me-2'} size={1} />
                    <ReactTimeAgo date={new Date()} />
                  </div>
                  <div>
                    <Icon path={mdiWeightGram} className={'text-danger'} size={1} /> {d.weight} %
                  </div>
                </div>
              );
            })}
          </div>
        </DetailPanel>
      </div>
      <div className="body-wrapper">
        <BaitcheckerList pagination={{page, limit, onPageChange, onLimitChange}} />
      </div>
    </>
  );
};
export default BaitCheckerInventory;
