import React from 'react';
import Icon from '@mdi/react';
import CountUp from 'react-countup';

interface StatusPanelProps {
  label: string;
  value: number | string;
  unit: string;
  icon: string;
  decimals?: number;
  thresholds?: Threshold[];
  thresholdIcons?: ThresholdIcon[];
  iconRotation?: number;
}

export interface ThresholdIcon {
  value: number;
  icon: string;
}

export interface Threshold {
  value: number | string;
  type: 'above' | 'below' | 'equals';
}

export const StatusPanel: React.FC<StatusPanelProps> = ({
  label,
  icon,
  value,
  unit,
  decimals = 0,
  thresholds,
  thresholdIcons,
  iconRotation = 0,
}) => {
  const isCriticalValue = value !== null && thresholds !== undefined && checkThresholds(value, thresholds);
  return (
    <div className={'flex-grow-1 d-flex pe-3 cur-pointer p-3 status-panel rounded-3'}>
      <div
        className={'rounded-2 status-icon text-center me-3 shadow-sm position-relative'}
        style={isCriticalValue ? {backgroundColor: 'rgba(255,0,0,0.11)'} : {}}>
        <Icon
          color={'#091D58'}
          path={getIconForThreshold(value, thresholdIcons) ?? icon}
          rotate={iconRotation}
          size={1.6}
          style={{marginTop: '10px'}}
        />
        {thresholds && isCriticalValue ? (
          <div className={'position-absolute bg-white p-1 rounded-circle shadow'} style={{right: -12, top: -12}}>
            <img src={'/static/images/svg/warning-status.svg'} width={24} height={24} alt="status" />
          </div>
        ) : null}
      </div>
      <div className={'position-relative'}>
        <div className={'label-hollow'}>{label}</div>
        <div
          className={'fw-bold position-absolute d-flex bottom-0 w-100 bold-heading overflow-x-scroll'}
          style={{
            fontSize: '2.0rem',
          }}>
          {value === null && '???'}
          {typeof value === 'number' ? <CountUp end={value} duration={1.5} decimals={decimals} /> : null}
          {typeof value === 'string' ? value : null}
          <span className={'fw-lighter small ms-1 opacity-50'} style={{fontSize: '1.4rem'}}>
            {unit}
          </span>
        </div>
      </div>
    </div>
  );

  function checkThresholds(value: string | number, thresholds: Threshold[]) {
    return thresholds.some(threshold => shouldShowAlert(value, threshold));
  }

  function shouldShowAlert(value: string | number, threshold: Threshold) {
    if (value === null) return false;
    if (typeof value === 'number' && typeof threshold.value === 'number') {
      switch (threshold.type) {
        case 'above':
          return value > threshold.value;
        case 'below':
          return value <= threshold.value;
        case 'equals':
          return value === threshold.value;
      }
    }
    return value === threshold.value;
  }

  function getIconForThreshold(value: string | number, thresholdIcons: ThresholdIcon[]) {
    if (!thresholdIcons) {
      return null;
    }
    if (typeof value === 'number') {
      for (let i = 0; i < thresholdIcons.length; i++) {
        const icon = thresholdIcons[i];
        if (icon.value > value) {
          return thresholdIcons[Math.max(i - 1, 0)].icon;
        }
        if (icon.value === value) {
          return thresholdIcons[i].icon;
        }
      }
    }
    return null;
  }
};
