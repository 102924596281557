import React from 'react';
import Icon from '@mdi/react';
import {mdiPlayCircleOutline} from '@mdi/js';

export const getStatusIcon: React.FC<{status: string}> = ({status}) => {
  switch (status) {
    case 'NORMAL':
      return <Icon path={mdiPlayCircleOutline} size={1} className={'text-success'} />;
    case 'INCIDENT':
      return <img src={'/static/images/svg/warning-status.svg'} width={24} height={24} alt="status" />;
  }
};
