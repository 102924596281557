import PageTitleWrapper from 'components/PageTitleWrapper';
import CommentsListing from 'components/baitchecker-inventory/comments/comments-listing/CommentsListing';
import BaitboxDeviceSettings from 'components/baitchecker-inventory/logs/baitbox-device-settings/BaitboxDeviceSettings';
import BaitBoxLogsList from 'components/baitchecker-inventory/logs/listing/BaitBoxLogsList';
import BaitBoxLogsPageHeader from 'components/baitchecker-inventory/logs/page-header/BaitBoxLogsPageHeader';
import {usePagination} from 'hooks/usePagination';
import useTranslate from 'hooks/useTranslate';
import {ActivityLogsDateFilterAction, ActivityLogsFilterAction} from 'models/inventory.interface';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {
  clearActivityLogsList,
  clearDateRangeFilter,
  clearLogsClientCompaniesFilterValues,
  clearLogsEventsFilterValues,
  clearLogsPestCompaniesFilterValues,
  updateActivityLogSortingKeyOrder,
  updateLogsSearchText,
} from 'redux/inventory/slice';
import {getBaiBoxActivityLogsAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {convertKeysToSnakeCase} from 'utils/object';
import {DetailPanel} from '../features/zbases/ui/shared/DetailPanel';
import {allExpanded, defaultStyles, JsonView} from 'react-json-view-lite';
import Accordion from 'react-bootstrap/Accordion';

// Component that renders details for a single baitbox
// @Deprecated
const BaitBoxLogs = () => {
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const {parseUI} = useTranslate();
  const [deviceSettings, setDeviceSettings] = useState(null);

  const {page, limit, onPageChange, onLimitChange} = usePagination(1, 10);

  const {
    logsSearchText,
    logsSortingKey,
    logsSortingOrder,
    logsPestCompaniesFilterValues,
    logsClientCompaniesFilterValues,
    logsEventsFilterValues,
    activityLogsDateRangeFilter,
  } = useAppSelector(getBaitCheckerState);

  const onTextChange = (text: string) => {
    dispatch(updateLogsSearchText({text}));
    if (page === 1) {
      dispatch(
        getBaiBoxActivityLogsAsync({
          baitBoxId: id,
          pageNumber: 1,
          limit,
          searchField: text,
          pestCompanyIds: logsPestCompaniesFilterValues,
          clientCompanyIds: logsClientCompaniesFilterValues,
          eventIds: logsEventsFilterValues,
          sortingField: logsSortingKey,
          sortingOrder: logsSortingOrder,
          startDate: activityLogsDateRangeFilter?.startDate,
          endDate: activityLogsDateRangeFilter?.endDate,
        }),
      );
    } else {
      onLimitChange(10);
    }
  };

  const onFiltersChange = (filterChangeAction: ActivityLogsFilterAction) => {
    if (page === 1) {
      if (filterChangeAction.status === 'apply') {
        dispatch(
          getBaiBoxActivityLogsAsync({
            baitBoxId: id,
            pageNumber: 1,
            limit,
            searchField: logsSearchText,
            pestCompanyIds: filterChangeAction.pestCompaniesFilters,
            clientCompanyIds: filterChangeAction.clientCompaniesFilters,
            eventIds: filterChangeAction.eventFilters,
            sortingField: logsSortingKey,
            sortingOrder: logsSortingOrder,
            startDate: activityLogsDateRangeFilter?.startDate,
            endDate: activityLogsDateRangeFilter?.endDate,
          }),
        );
      }

      if (filterChangeAction.status === 'remove') {
        dispatch(
          getBaiBoxActivityLogsAsync({
            baitBoxId: id,
            pageNumber: 1,
            limit,
            searchField: logsSearchText,
            pestCompanyIds: [],
            clientCompanyIds: [],
            eventIds: [],
            sortingField: logsSortingKey,
            sortingOrder: logsSortingOrder,
            startDate: activityLogsDateRangeFilter?.startDate,
            endDate: activityLogsDateRangeFilter?.endDate,
          }),
        );
      }

      if (filterChangeAction.status === 'close') {
        getBaitBoxActivityLogsList();
      }
    } else {
      onLimitChange(10);
    }
  };

  const onDateFiltersChange = (dateFilterAction: ActivityLogsDateFilterAction) => {
    if (page === 1) {
      if (dateFilterAction.status === 'apply') {
        dispatch(
          getBaiBoxActivityLogsAsync({
            baitBoxId: id,
            pageNumber: 1,
            limit,
            searchField: logsSearchText,
            pestCompanyIds: logsPestCompaniesFilterValues,
            clientCompanyIds: logsClientCompaniesFilterValues,
            eventIds: logsEventsFilterValues,
            sortingField: logsSortingKey,
            sortingOrder: logsSortingOrder,
            startDate: dateFilterAction.startDate,
            endDate: dateFilterAction.endDate,
          }),
        );
      }
      if (dateFilterAction.status === 'remove') {
        dispatch(
          getBaiBoxActivityLogsAsync({
            baitBoxId: id,
            pageNumber: 1,
            limit,
            searchField: logsSearchText,
            pestCompanyIds: logsPestCompaniesFilterValues,
            clientCompanyIds: logsClientCompaniesFilterValues,
            eventIds: logsEventsFilterValues,
            sortingField: logsSortingKey,
            sortingOrder: logsSortingOrder,
            startDate: null,
            endDate: null,
          }),
        );
      }
    } else {
      onLimitChange(10);
    }
  };

  const getBaitBoxActivityLogsList = () => {
    dispatch(
      getBaiBoxActivityLogsAsync({
        baitBoxId: id,
        pageNumber: page,
        limit,
        searchField: logsSearchText,
        pestCompanyIds: logsPestCompaniesFilterValues,
        clientCompanyIds: logsClientCompaniesFilterValues,
        eventIds: logsEventsFilterValues,
        sortingField: logsSortingKey,
        sortingOrder: logsSortingOrder,
        startDate: activityLogsDateRangeFilter?.startDate,
        endDate: activityLogsDateRangeFilter?.endDate,
      }),
    );
  };

  useEffect(() => {
    if (page && limit) {
      getBaitBoxActivityLogsList();
    }
  }, [page, limit, logsSortingKey, logsSortingOrder]);

  useEffect(() => {
    //cleanup the state when component unmount
    return () => {
      dispatch(updateLogsSearchText({text: null}));
      dispatch(
        updateActivityLogSortingKeyOrder({
          sortingKeyOrder: {sortingKey: null, sortingOrder: null},
        }),
      );
      dispatch(clearLogsPestCompaniesFilterValues());
      dispatch(clearLogsClientCompaniesFilterValues());
      dispatch(clearLogsEventsFilterValues());
      dispatch(clearActivityLogsList());
      dispatch(clearDateRangeFilter());
    };
  }, []);

  /**
   * @desc: convert and set device settings
   * @param deviceSettings
   */
  const convertAndSetDeviceSettings = (deviceSettings: any) => {
    setDeviceSettings(convertKeysToSnakeCase(deviceSettings));
  };

  return (
    <>
      <div className={'d-none'}>
        <PageTitleWrapper>
          <BaitBoxLogsPageHeader
            textChange={onTextChange}
            onFiltersChange={onFiltersChange}
            onDateFiltersChange={onDateFiltersChange}
            setDeviceSettings={convertAndSetDeviceSettings}
          />
        </PageTitleWrapper>
      </div>
      <DetailPanel heading={parseUI('CONFIGURE_DEVICE_SETTINGS_RESPONSE')}>
        <BaitboxDeviceSettings setDeviceSettings={convertAndSetDeviceSettings} />
        {deviceSettings && (
          <div className="configured-device-setting">
            <Accordion className={'mb-3'}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{parseUI('CONFIGURED_DEVICE_SETTINGS')}</Accordion.Header>
                <Accordion.Body>
                  <JsonView
                    data={deviceSettings}
                    style={{
                      ...defaultStyles,
                      label: 'fw-light text-primary font-monospace',
                      container: 'bg-white',
                    }}
                    shouldExpandNode={allExpanded}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </DetailPanel>
      <DetailPanel heading={parseUI('COMMENTS_SECTION')}>
        <CommentsListing />
      </DetailPanel>

      <DetailPanel heading={parseUI('EVENT_LOGS')}>
        <BaitBoxLogsList pagination={{page, limit, onPageChange, onLimitChange}} />
      </DetailPanel>
    </>
  );
};

export default BaitBoxLogs;
