import * as React from 'react';
import Popper from '@mui/material/Popper';
import {useItemTooltip, useMouseTracker} from '@mui/x-charts/ChartsTooltip';
import {generateVirtualElement} from './generateVirtualElement';
import {renderDate} from './ConnectivityPanel';
import {Datapoint} from '../models/ZBaseApiModels';

interface CustomItemTooltipProps {
  data?: Datapoint[];
  markerPositions: {x: number; y: number; relX: number; relY: number}[];
}

export function CustomItemTooltip({data, markerPositions}: CustomItemTooltipProps) {
  const tooltipData = useItemTooltip();
  const mousePosition = useMouseTracker(); // Track the mouse position on chart.

  if (!tooltipData || !mousePosition) {
    // No data to display
    return null;
  }

  const payload = data[tooltipData.identifier.dataIndex];

  // The pointer type can be used to have different behavior based on pointer type.
  const isMousePointer = mousePosition?.pointerType === 'mouse';
  // Adapt the tooltip offset to the size of the pointer.
  const yOffset = isMousePointer ? 0 : 40 - mousePosition.height;

  return (
    <Popper
      className={'bg-white'}
      sx={{
        pointerEvents: 'none',
        zIndex: theme => theme.zIndex.modal,
      }}
      open
      placement={isMousePointer ? 'top-end' : 'top'}
      anchorEl={generateVirtualElement(mousePosition)}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, yOffset],
          },
        },
      ]}>
      <div className={'shadow p-3 bg-white rounded-3'}>
        <div className={'d-flex align-items-center justify-content-between'}>
          <div
            style={{
              width: 11,
              height: 11,
              borderRadius: '50%',
              border: '2px solid ' + tooltipData.color,
            }}
          />
          {tooltipData.label}
          <span className={'bold-heading'}>{tooltipData.value}</span>
        </div>
        <div className={'fw-lighter'}>{renderDate(payload.date)}</div>
      </div>
    </Popper>
  );
}
