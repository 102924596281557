import React, {FC, useEffect, useState} from 'react';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {clearDateRangeFilter, updateDateRangeFilter} from 'redux/inventory/slice';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getToday, getYesterday} from 'utils/format-date';
import {ActivityLogsDateFilterAction, DateFilterStatus} from 'models/inventory.interface';
import Button from 'react-bootstrap/Button';

interface DateRangePickerComponentProps {
  onDateFiltersChange: (dateFilterAction: ActivityLogsDateFilterAction) => void;
}

const DateRangePickerComponent: FC<DateRangePickerComponentProps> = ({onDateFiltersChange}) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const {activityLogsDateRangeFilter} = useAppSelector(getBaitCheckerState);

  const dispatch = useAppDispatch();

  const today = getToday();
  const yesterday = getYesterday();

  useEffect(() => {
    return () => {
      handleClearDateRange();
    };
  }, []);

  const applyDateRangeFilter = () => {
    dispatch(updateDateRangeFilter({startDate, endDate}));
    onDateFiltersChange({
      status: DateFilterStatus.APPLY,
      startDate,
      endDate,
    });
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateFiltersChange({
      status: DateFilterStatus.APPLY,
      startDate: newStartDate,
      endDate: endDate,
    });
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateFiltersChange({
      status: DateFilterStatus.APPLY,
      startDate: startDate,
      endDate: newEndDate,
    });
  };

  const showDateSubmitButton = () => {
    return startDate && endDate;
  };

  const handleClearDateRange = () => {
    setStartDate('');
    setEndDate('');
    dispatch(clearDateRangeFilter());
    onDateFiltersChange({
      status: DateFilterStatus.REMOVE,
      startDate: null,
      endDate: null,
    });
  };

  return (
    <div className="date-filter-logs">
      <div className="from-sec">
        <label>From</label>
        <input type="date" value={startDate} onChange={handleStartDateChange} max={yesterday} />
      </div>
      <div className="to-sec">
        <label>To</label>
        <input type="date" value={endDate} onChange={handleEndDateChange} max={today} />
      </div>

      {(startDate || endDate) && (
        <Button variant={'secondary'} onClick={handleClearDateRange}>
          Clear Date Filter
        </Button>
      )}
    </div>
  );
};

export default DateRangePickerComponent;
