import {CssBaseline} from '@mui/material';
import {Suspense, useEffect} from 'react';
import {useRoutes} from 'react-router-dom';

import {STORAGE, TOKEN} from 'constants/local-storage-keys';
import {getKey, setKey} from 'utils/local-storage';
import Loader from './components/shared/loader/Loader';
import Routes from './routers';
import ThemeProvider from './theme/ThemeProvider';

import './fonts/Gotham-Light.ttf';

function App() {
  const content = useRoutes(Routes);

  useEffect(() => {
    const language = getKey('i18nextLng') ?? navigator.language.split('-')[0];
    setKey('i18nextLng', language);
    window.addEventListener(STORAGE, event => {
      if (event.key === TOKEN && document.hidden) {
        window.location.reload();
      }
    });
    return () => {
      window.removeEventListener(STORAGE, () => null);
    };
  }, []);

  return (
    <ThemeProvider>
      <CssBaseline />
      <Suspense fallback={<Loader show={true} />}>{content}</Suspense>
    </ThemeProvider>
  );
}

export default App;
