import React from 'react';
import {Card} from 'react-bootstrap';

interface DetailPanelProps {
  header?: React.ReactNode | (() => React.ReactNode);
  heading: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  overflow?: string;
}

export const DetailPanel: React.FC<DetailPanelProps> = ({
  header,
  heading,
  children,
  className = '',
  style = {},
  overflow = 'scroll',
}) => {
  return (
    <Card className={`rounded-4 overflow-${overflow} shadow-sm mb-4 ${className}`} style={{border: 'none', ...style}}>
      <Card.Header className={`bg-white ${className} pt-3 px-4`} style={{border: 'none'}}>
        <div className={'d-flex justify-content-between align-items-center'}>
          <h3 className={'bold-heading'}>{heading}</h3>
          {typeof header === 'function' ? header() : header}
        </div>
      </Card.Header>
      <Card.Body className="p-2 pt-3 px-4">{children}</Card.Body>
    </Card>
  );
};
