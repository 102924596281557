/* eslint-disable sonarjs/no-duplicate-string */
import {ArrowDownward} from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import NetworkLockedIcon from '@mui/icons-material/NetworkLocked';
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

import BatteryIndicator from 'components/UI/BatteryIndicator';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import InfoTooltip from 'components/UI/InfoTooltip';
import SignalIndicator from 'components/UI/SignalIndicator';
import {EMPTY_STRING} from 'constants/text-strings';
import useDialog from 'hooks/useDialog';
import useTranslate from 'hooks/useTranslate';
import {BaitBoxAction, BaitBoxStatus, ClientBaitBoxDeactivationStatus} from 'models/baitbox-actions';
import {HttpError, HttpResponse} from 'models/http-interface';
import {UseTableCheckBoxListData} from 'models/pest-company-interface';
import {useSearchParams} from 'react-router-dom';
import {selectPestCompanyData} from 'redux/pest-company/selector';
import {updatePestBaitBoxSortingKeyAndOrder} from 'redux/pest-company/slice';
import {
  getPestCompanyBaitBoxesAsync,
  getPestDetailsWithBaitBoxesAsync,
  markBaitBoxDeactiveAsync,
  markBaitBoxRetireAsync,
  markBaitBoxReturnToZeptaAsync,
} from 'redux/pest-company/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getStatusIconText} from 'utils/baitbox-status';
import {getBatteryPercentage} from 'utils/battery-indicator';
import {daysFromDate, germanDateFormat} from 'utils/format-date';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {mdiChevronUp} from '@mdi/js';
import Icon from '@mdi/react';
import {updateInventoryDetails} from '../../../redux/inventory/slice';

const {DEACTIVATE, RETURN_TO_ZEPTA, RETIRE} = BaitBoxAction;

const initialSortState = {
  column: null,
  sortingOrder: null,
};

type PestBaitBoxListProp = {
  data: UseTableCheckBoxListData[];
  toggleCheckBox: (iccHash: string) => void;
  toggleCheckAll: () => void;
  markCheckUnCheck: (check: boolean) => void;

  //for page refresh on actions on baitboxes
  textChange?: (text: string) => void;
  isCheckAll: boolean;
};

const PestCompanyBaitBoxes: FC<PestBaitBoxListProp> = ({
  data,
  textChange,
  toggleCheckBox,
  markCheckUnCheck,
  isCheckAll,
}) => {
  const [parameter] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {parseUI} = useTranslate();
  const {id} = useParams();
  const {isOpen, showDialog, dialogData, hideDialog} = useDialog(false);
  const [dialogBody, setDialogBody] = useState<string>('');

  const {baitboxSearchText, baitboxSortingKey, baitboxSortingOrder} = useAppSelector(selectPestCompanyData);

  const [sortState, setSortState] = useState(initialSortState);

  const changeOrder = () => {
    if (sortState.sortingOrder === 1) {
      setSortState({
        column: null,
        sortingOrder: null,
      });
    }

    if (sortState.sortingOrder === 0) {
      setSortState(prevState => ({
        column: prevState.column,
        sortingOrder: 1,
      }));
    }

    if (sortState.sortingOrder === -1) {
      setSortState(prevState => ({
        column: prevState.column,
        sortingOrder: 0,
      }));
    }
  };

  const queryPage = parameter.get('page') ?? '1';
  const queryLimit = parameter.get('limit') ?? '10';

  const columnDefinitions = [
    {
      label: <input type="checkbox" checked={isCheckAll} onChange={() => markCheckUnCheck(!isCheckAll)} />,
      key: 'selectAll',
      sortable: false,
    },
    {key: 'status', label: 'STATUS', sortable: false},
    {key: 'simId', label: 'SIM_ID', sortable: true},
    {key: 'weight', label: 'WEIGHT', sortable: true},
    {key: 'slug', label: 'UNIQUE_ID', sortable: true},
    {key: 'activeSinceDays', label: 'ACTIVE_SINCE_DAYS', sortable: true},
    {
      key: 'lastBroadcastDate',
      label: 'LAST_BROADCAST_DATE',
      sortable: true,
    },
    {key: 'signal', label: 'SIGNAL', sortable: true},
    {key: 'batteryLevel', label: 'BATTERY_LEVEL', sortable: true},
    {key: 'actions', label: 'ACTIONS', sortable: false},
  ];

  const sort = (columnName: string) => {
    if (columnName === sortState.column) {
      changeOrder();
    } else {
      setSortState({
        column: columnName,
        sortingOrder: 0,
      });
    }
  };

  const renderHeading = () =>
    columnDefinitions.length > 0 &&
    columnDefinitions.map(column => (
      <TableCell
        key={column.key}
        onClick={() => (column.sortable ? sort(column.key) : null)}
        style={column.sortable ? {cursor: 'pointer'} : {}}
        width={column.key === 'status' || column.key === 'simId' ? 60 : undefined}>
        {typeof column.label === 'string' ? parseUI(column.label) : column.label}

        <button className="sort-btn">
          {column.sortable &&
            sortState.column === column.key &&
            (!sortState.sortingOrder ? (
              <Icon path={mdiChevronUp} size={0.9} />
            ) : (
              <ArrowDownward style={{fontSize: '14px', cursor: 'pointer'}} />
            ))}
        </button>
      </TableCell>
    ));

  const handleAction = async (
    iccHash: string,
    clientCompanyId: string,
    clientBaitBoxId: string,
    actionOnBaitbox: number,
    message: string,
  ) => {
    setDialogBody(message);
    showDialog({iccHash, clientCompanyId, clientBaitBoxId, actionOnBaitbox});
  };

  const hideDialogPopup = async () => {
    hideDialog();
    const {iccHash, clientCompanyId, clientBaitBoxId, actionOnBaitbox} = dialogData;

    try {
      let response: HttpResponse<null>;
      if (actionOnBaitbox == BaitBoxAction.RETURN_TO_ZEPTA) {
        // return to zepta
        response = await dispatch(markBaitBoxReturnToZeptaAsync(iccHash)).unwrap();
      } else if (actionOnBaitbox == BaitBoxAction.RETIRE) {
        // retire
        response = await dispatch(markBaitBoxRetireAsync(iccHash)).unwrap();
      } else {
        const deactivateBaitBoxBody = {
          pestCompanyId: id,
          clientCompanyId,
          clientBaitBoxId,
        };

        response = await dispatch(markBaitBoxDeactiveAsync(deactivateBaitBoxBody)).unwrap();
      }

      dispatch(getPestDetailsWithBaitBoxesAsync(id));
      if (response) {
        if (actionOnBaitbox === BaitBoxAction.DEACTIVATE) {
          // refresh the current page with the pagination value of current page .
          dispatch(
            getPestCompanyBaitBoxesAsync({
              pestCompanyId: id,
              pageNumber: parseInt(queryPage),
              limit: parseInt(queryLimit),
              searchField: baitboxSearchText,
              sortingField: baitboxSortingKey,
              sortingOrder: baitboxSortingOrder,
            }),
          );
        } else {
          // to change the current page 1 in query param and call the api for page 1 as bait boxes removed from the
          // list when we return to zepta or retire

          textChange(null);
        }
      }

      showSuccess(response.message);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  const renderRows = () =>
    data.length > 0 &&
    data.map(
      ({
        iccNumber,
        slug,
        iccHash,
        clientCompanyId,
        clientBaitBoxId,
        currentWeight,
        status,
        isActive,
        batteryLevel,
        uuid,
        networkStrength,
        createdAt,
        updatedAt,
        isDeactivation,
        isChecked,
      }) => {
        return (
          <TableRow key={iccNumber}>
            <TableCell>
              <input type="checkbox" checked={isChecked} onChange={() => toggleCheckBox(iccHash)} />
            </TableCell>

            <TableCell width={60}>
              <InfoTooltip name={parseUI(getStatusIconText(status, isActive, false).text)}>
                <img src={getStatusIconText(status, isActive, false).icon} width={24} height={24} alt="status" />
              </InfoTooltip>
            </TableCell>
            <TableCell>{iccNumber}</TableCell>
            <TableCell>{currentWeight}</TableCell>
            <TableCell
              className={'font-monospace hoverable cur-pointer'}
              onClick={() => {
                navigate(`../bait-checker-list/logs/${uuid}`);
              }}>
              {slug}
            </TableCell>
            <TableCell>{createdAt ? daysFromDate(createdAt) : EMPTY_STRING}</TableCell>
            <TableCell>{updatedAt ? germanDateFormat(updatedAt) : EMPTY_STRING}</TableCell>
            <TableCell>
              {!isNaN(networkStrength) ? (
                <>
                  {networkStrength > 0 ? (
                    <span>
                      <SignalIndicator signalValue={networkStrength} />
                    </span>
                  ) : (
                    <img src="/static/images/svg/no-signal.svg" width={24} height={24} alt="no_signal" />
                  )}
                </>
              ) : (
                EMPTY_STRING
              )}
            </TableCell>
            <TableCell>
              {!isNaN(batteryLevel) ? (
                <InfoTooltip name={getBatteryPercentage(batteryLevel)}>
                  <span>
                    <BatteryIndicator value={batteryLevel} />
                  </span>
                </InfoTooltip>
              ) : (
                EMPTY_STRING
              )}
            </TableCell>

            <TableCell>
              <InfoTooltip
                name={isDeactivation ? parseUI('REQUESTED_FOR_DEACTIVATION') : parseUI('DEACTIVATE')}
                className={isActive === BaitBoxStatus.ACTIVE ? 'cur-pointer' : 'not-allowed'}>
                <IconButton
                  onClick={() =>
                    handleAction(
                      iccHash,
                      clientCompanyId,
                      clientBaitBoxId,
                      DEACTIVATE,
                      'WANT_TO_DEACTIVATE_SELECTED_BAITCHECKERS',
                    )
                  }
                  disabled={
                    isDeactivation === ClientBaitBoxDeactivationStatus.PENDING || isActive === BaitBoxStatus.DEACTIVE
                  }
                  aria-label="View"
                  className="baitbox_action_icons">
                  <NetworkLockedIcon />
                </IconButton>
              </InfoTooltip>

              <InfoTooltip
                name={parseUI('RETURN_TO_ZEPTA')}
                className={isActive === BaitBoxStatus.ACTIVE ? 'not-allowed' : 'cur-pointer'}>
                <IconButton
                  onClick={() =>
                    handleAction(iccHash, clientCompanyId, clientBaitBoxId, RETURN_TO_ZEPTA, 'RETURN_TO_ZEPTA')
                  }
                  disabled={isActive === BaitBoxStatus.ACTIVE}
                  aria-label="View"
                  className="baitbox_action_icons">
                  <KeyboardReturnIcon />
                </IconButton>
              </InfoTooltip>

              <InfoTooltip
                name={parseUI('RETIRE')}
                className={isActive === BaitBoxStatus.ACTIVE ? 'not-allowed' : 'cur-pointer'}>
                <IconButton
                  onClick={() => handleAction(iccHash, clientCompanyId, clientBaitBoxId, RETIRE, 'MARK_RETIRE')}
                  disabled={isActive === BaitBoxStatus.ACTIVE}
                  aria-label="View"
                  className="baitbox_action_icons">
                  <BlockIcon color="error" />
                </IconButton>
              </InfoTooltip>
            </TableCell>
          </TableRow>
        );
      },
    );

  useEffect(() => {
    dispatch(
      updatePestBaitBoxSortingKeyAndOrder({
        sortingKeyOrder: {
          sortingKey: sortState.column,
          sortingOrder: sortState.sortingOrder,
        },
      }),
    );
  }, [sortState]);

  return (
    <>
      <TableContainer component={Paper} className="common-table detail-pest">
        <Table>
          <TableHead>
            <TableRow>{renderHeading()}</TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>

      <ConfirmDialog
        isLoading={false}
        initialValue={isOpen}
        onClose={() => hideDialog()}
        successMessage={parseUI('YES')}
        cancelMessage={parseUI('CANCEL')}
        onSuccess={hideDialogPopup}
        heading={parseUI('ARE_YOU_SURE')}
        body={parseUI(dialogBody)}
      />
    </>
  );
};

export default PestCompanyBaitBoxes;
