import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {
  areaElementClasses,
  AreaPlot,
  axisClasses,
  ChartContainer,
  chartsAxisHighlightClasses,
  ChartsGrid,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
} from '@mui/x-charts';
import {CustomItemTooltip} from './CustomTooltip';
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiBattery,
  mdiEmoticonCoolOutline,
  mdiLightningBoltOutline,
  mdiMinusCircleOutline,
  mdiPlusCircleOutline,
  mdiRestart,
  mdiSkipBackwardOutline,
  mdiSkipForwardOutline,
  mdiSkipNextOutline,
  mdiSkipPreviousOutline,
  mdiThermometer,
} from '@mdi/js';
import Icon from '@mdi/react';
import Button from 'react-bootstrap/Button';
import {ButtonGroup} from 'react-bootstrap';
import {defaultStyles, JsonView} from 'react-json-view-lite';
import {renderDate} from './ConnectivityPanel';
import {Loader} from 'react-feather';
import {Datapoint} from '../models/ZBaseApiModels';
import CountUp from 'react-countup';

interface ChartProps {
  data: Datapoint[];
}

interface MarkerPosition {
  x: number;
  y: number;
  relX: number;
  relY: number;
  width: number;
  height: number;
}

const MAX_ZOOM = 5000;
export const Chart: React.FC<ChartProps> = ({data}) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [axisOpacity, setAxisOpacity] = useState(1);
  const [markerPositions, setMarkerPositions] = useState<MarkerPosition[]>([]);
  const [selection, setSelection] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [selectionIndexes, setSelectionIndexes] = useState<number[]>([]);
  const [rects, setRects] = useState([]);
  const [width, setWidth] = useState(0);

  let scrollTimeout = null;

  useEffect(() => {
    const handleScroll = () => {
      if (chartContainerRef.current) {
        setAxisOpacity(0);
        setScrollLeft(chartContainerRef.current.scrollLeft);
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => setAxisOpacity(1), 150);
      }
    };

    const container = chartContainerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const markerBoundingRectangles = markerPositions.map(p => {
      return {x: p.x - 50, y: p.y, width: 100, height: 500};
    });
    setRects(markerBoundingRectangles);
  }, [markerPositions]);

  useEffect(() => {
    if (chartContainerRef.current) {
      setWidth(Math.max(chartContainerRef.current.clientWidth, 80 * data.length * zoom));
    }
  }, [chartContainerRef, zoom]);

  useEffect(() => {
    let smallestMarkerPositionInSelection: {index: number; markerPosition: MarkerPosition};
    if (selectionIndexes.length < 1) {
      smallestMarkerPositionInSelection = {
        index: 0,
        markerPosition: {x: 0, y: 0, relX: 0, relY: 0, width: 0, height: 0},
      };
    } else {
      smallestMarkerPositionInSelection = selectionIndexes
        .map(index => ({index, markerPosition: markerPositions[index]}))
        .reduce((mp1, mp2) => (mp1.markerPosition.x < mp2.markerPosition.x ? mp1 : mp2));
    }
    scrollToMarker(smallestMarkerPositionInSelection.markerPosition);
    setCurrentIndex(smallestMarkerPositionInSelection.index);
  }, [markerPositions, selectionIndexes]);

  const scrollToMarker = (markerPosition: MarkerPosition) => {
    const container = chartContainerRef.current;
    if (container) {
      container.scrollLeft = markerPosition.x - container.clientWidth / 2;
    }
  };

  const collectMarkerPositions = () => {
    if (chartContainerRef.current) {
      const parentRect = chartContainerRef.current.getBoundingClientRect();
      const paths = chartContainerRef.current.querySelectorAll(
        "[class^='MuiMarkElement-root MuiMarkElement-series-cookies']",
      );
      const positions = Array.from(paths).map(path => {
        // @ts-expect-error no typings available
        const rect = path.getBoundingClientRect();

        const relativeX = rect.x - parentRect.x;
        const relativeY = rect.y - parentRect.y;

        // @ts-expect-error no typings available
        const transformStyle = path.style.transform;
        const match = transformStyle.match(/translate\(([^px]+)px, ([^px]+)px\)/);
        if (match) {
          return {
            x: parseFloat(match[1]),
            y: parseFloat(match[2]),
            width: 0,
            height: 0,
            relX: relativeX,
            relY: relativeY,
          };
        }

        return {x: relativeX, y: relativeY, width: rect.width + 50, height: rect.height, relX: 0, relY: 0};
      });
      setMarkerPositions(positions);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = chartContainerRef.current;
    const rect = container.getBoundingClientRect();
    const start = {
      x: e.clientX - rect.left + container.scrollLeft,
      y: e.clientY - rect.top + container.scrollTop,
    };
    setStartPoint(start);
    setSelection({x: e.clientX, y: e.clientY, width: 0, height: 0});
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (markerPositions.length === 0) {
      collectMarkerPositions();
    }
    if (!startPoint || !selection) return;
    const container = chartContainerRef.current;
    const rect = container.getBoundingClientRect();
    const currentPoint = {
      x: e.clientX - rect.left + container.scrollLeft,
      y: e.clientY - rect.top + container.scrollTop,
    };

    setSelection({
      x: Math.min(startPoint.x, currentPoint.x),
      y: Math.min(startPoint.y, currentPoint.y),
      width: Math.abs(currentPoint.x - startPoint.x),
      height: Math.abs(currentPoint.y - startPoint.y),
    });
  };

  const handleMouseUp = () => {
    if (!startPoint || !selection) return;

    if (selection.width < 10) {
      setSelection(null);
      setStartPoint(null);
      return;
    }

    const newZoom = Math.min(MAX_ZOOM, chartContainerRef.current.clientWidth / Math.max(1, selection.width));
    setZoom(newZoom);
    const indexes = rects
      .map((rect, index) => ({rect, index}))
      .filter(rect => rect.rect.x < selection.x + selection.width && rect.rect.x + rect.rect.width > selection.x)
      .map(rect => rect.index);
    setSelectionIndexes(indexes);
    setTimeout(collectMarkerPositions, 100);

    setSelection(null);
    setStartPoint(null);
  };

  const handleNavigation = (direction: number) => {
    if (markerPositions.length === 0) {
      collectMarkerPositions();
      return;
    }
    let next = currentIndex + direction;
    next = Math.max(0, Math.min(markerPositions.length - 1, next));
    setCurrentIndex(next);
    scrollToMarker(markerPositions[next]);
  };

  if (!data) {
    return <Loader />;
  }
  if (data.length === 0) {
    return <div>No data.</div>;
  }
  const datapoint = data[currentIndex];
  const previous = data[Math.max(0, currentIndex - 1)];
  const delta = (datapoint.originalValue ?? datapoint.value) - (previous.originalValue ?? previous.value);
  const type = delta < 0 ? 'decrement' : 'increment';
  const currentMarker = markerPositions[currentIndex];
  return (
    <>
      <div className={'d-flex justify-content-between pb-3'}>
        <ButtonGroup style={{transform: 'scale(0.9)'}}>
          <Button
            variant={'secondary'}
            className={'bg-transparent text-primary'}
            disabled={currentIndex === 0}
            onClick={() => handleNavigation(-currentIndex)}>
            <Icon path={mdiSkipBackwardOutline} size={1} className={currentIndex === 0 ? 'opacity-50' : ''} />
          </Button>
          <Button
            variant={'secondary'}
            className={'bg-transparent text-primary'}
            disabled={currentIndex === 0}
            onClick={() => handleNavigation(-1)}>
            <Icon path={mdiSkipPreviousOutline} size={1} className={currentIndex === 0 ? 'opacity-50' : ''} />
          </Button>
        </ButtonGroup>
        <div className={'text-center'} style={{fontVariantNumeric: 'tabular-nums'}}>
          <div style={{fontSize: '0.9rem'}}>
            {renderDate(datapoint.date, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </div>
          <div className={'bold-heading m-0 p-0'} style={{fontSize: '1.7rem', fontWeight: 500}}>
            {renderDate(datapoint.date, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hourCycle: 'h23',
            })}
          </div>
        </div>
        <ButtonGroup style={{transform: 'scale(0.9)'}}>
          <Button
            variant={'secondary'}
            className={'bg-transparent text-primary'}
            disabled={currentIndex === markerPositions.length - 1}
            onClick={() => handleNavigation(1)}>
            <Icon
              path={mdiSkipNextOutline}
              size={1}
              className={currentIndex === markerPositions.length - 1 ? 'opacity-50' : ''}
            />
          </Button>
          <Button
            variant={'secondary'}
            className={'bg-transparent text-primary'}
            disabled={currentIndex === markerPositions.length - 1}
            onClick={() => handleNavigation(data.length - 1)}>
            <Icon
              path={mdiSkipForwardOutline}
              size={1}
              className={currentIndex === markerPositions.length - 1 ? 'opacity-50' : ''}
            />
          </Button>
        </ButtonGroup>
      </div>
      <div
        className={'container-fluid text-primary p-3 rounded-2'}
        style={{
          border: '1px solid #f2f5f9',
          fontSize: 18,
        }}>
        <div className={'row'}>
          <div className={'col-12 d-flex justify-content-between text-center'}>
            <div className={'row w-100'}>
              <div className={'col-4 d-flex justify-content-start'}>
                <Icon path={mdiThermometer} size={1} /> {datapoint.temperature?.toFixed(1) ?? '--.-'} °C
              </div>
              <div className={'col-4 flex-grow-1 justify-content-center'}>
                <div className={'fw-bold bold-heading mx-auto px-2'} style={{fontSize: '2.5rem', width: 250}}>
                  <CountUp
                    start={Math.floor(previous.originalValue ?? previous.value)}
                    end={Math.floor(datapoint.originalValue ?? datapoint.value)}
                    duration={0.7}
                  />
                  {'.'}
                  <span className={'fw-lighter'} style={{fontSize: '1.4rem'}}>
                    {(datapoint.originalValue ?? datapoint.value).toFixed(2).split('.')[1]}
                    <span className={'ms-1'}>%</span>
                  </span>
                </div>
                <div
                  className={'fw-lighter d-flex justify-content-center align-items-center'}
                  style={{fontSize: '1rem'}}>
                  {type === 'increment' && delta > 0 && (
                    <Icon className={'text-warning'} path={mdiArrowUp} size={0.6} />
                  )}
                  {type === 'decrement' && <Icon className={'text-danger'} path={mdiArrowDown} size={0.6} />}
                  <div>{Math.abs(delta).toFixed(2)} %</div>
                </div>
              </div>
              <div className={'col-4 flex-grow-1 d-flex justify-content-end'}>
                <Icon path={mdiBattery} size={1} /> {datapoint.batteryLevel} %
              </div>
            </div>
          </div>
          <div className={'col-12 mt-1 d-flex justify-content-end'}>
            {!datapoint.measurement?.errors && (
              <div className={'text-success small'}>
                <Icon path={mdiEmoticonCoolOutline} size={1} /> No Errors
              </div>
            )}
            {datapoint.measurement?.errors && renderErrors(datapoint.measurement.errors)}
          </div>
        </div>
      </div>

      <div
        onKeyDown={e => {
          if (e.key === 'ArrowLeft') {
            handleNavigation(-1);
          }
          if (e.key === 'ArrowRight') {
            handleNavigation(1);
          }
        }}
        tabIndex={0}
        className={'position-relative'}>
        <div
          className={'d-flex position-absolute bottom-0 end-0 z-3'}
          style={{paddingBottom: 50, paddingRight: 20, transform: 'scale(0.75)'}}>
          <ButtonGroup vertical style={{backgroundColor: 'rgba(255,255,255,0.83)'}}>
            <Button
              variant={'secondary'}
              onClick={() => {
                setZoom(zoom / 1.3);
                setSelectionIndexes([]);
                setTimeout(collectMarkerPositions, 100);
              }}>
              <Icon path={mdiMinusCircleOutline} size={1} />
            </Button>
            <Button
              variant={'secondary'}
              onClick={() => {
                setZoom(1);
                setSelectionIndexes([]);
                setTimeout(collectMarkerPositions, 100);
              }}>
              <Icon path={mdiRestart} size={1} />
            </Button>
            <Button
              variant={'secondary'}
              onClick={() => {
                setZoom(zoom * 1.3);
                setSelectionIndexes([]);
                setTimeout(collectMarkerPositions, 100);
              }}>
              <Icon path={mdiPlusCircleOutline} size={1} />
            </Button>
          </ButtonGroup>
        </div>
        <Box sx={{width: '100%', overflowX: 'scroll', scrollBehavior: 'smooth'}} ref={chartContainerRef}>
          <div
            ref={chartRef}
            style={{width}}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}>
            {data && (
              <ChartContainer
                width={width}
                skipAnimation={true}
                colors={['#0D9F73', '#FF5020', '#6C9DF2']}
                dataset={data}
                xAxis={[
                  {
                    scaleType: 'time',
                    dataKey: 'date',
                    id: 'quarters',
                  },
                ]}
                yAxis={[
                  {
                    id: 'quantities',
                    scaleType: 'linear',
                    min: -10,
                    valueFormatter: value => `${value} %`,
                  },
                ]}
                series={[
                  {
                    label: 'Battery (%)',
                    type: 'line',
                    id: 'battery',
                    yAxisId: 'quantities',
                    dataKey: 'batteryLevel',
                    connectNulls: true,
                    valueFormatter: value => `${value.toFixed(2)} %`,
                  },
                  {
                    label: 'Temperature (°C)',
                    type: 'line',
                    id: 'temperature',
                    yAxisId: 'quantities',
                    dataKey: 'temperature',
                    area: true,
                    connectNulls: true,
                    valueFormatter: value => `${value.toFixed(2)} %`,
                  },
                  {
                    label: 'Weight (%)',
                    type: 'line',
                    id: 'cookies',
                    yAxisId: 'quantities',
                    dataKey: 'value',
                    area: true,
                    valueFormatter: value => `${value.toFixed(2)} %`,
                  },
                ]}
                height={500}
                margin={{left: 70, right: 70, top: 20, bottom: 70}}
                sx={{
                  [`.${axisClasses.left}`]: {},
                  [`.${axisClasses.root}`]: {
                    fontSize: '200px !important',
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#3A4A79',
                      strokeWidth: 2,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                      fill: 'rgb(9,29,88)',
                    },
                  },
                  [`.${areaElementClasses.root}`]: {fill: 'url(#Gradient2)'},
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: 'translate(-20px, 0)',
                  },
                  [`.${chartsAxisHighlightClasses.root}`]: {stroke: '#6C9DF2', strokeWidth: 2},
                }}>
                <defs>
                  <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stopColor={'rgba(108,157,242,0.07)'} />
                    <stop offset="50%" stopColor={'black'} stopOpacity={'0'} />
                    <stop offset="100%" stopColor={'transparent'} />
                  </linearGradient>
                </defs>
                <ChartsGrid vertical horizontal />
                <CustomItemTooltip data={data} markerPositions={markerPositions} />
                <AreaPlot skipAnimation={true} />
                <LinePlot skipAnimation={true} />
                <ChartsXAxis
                  axisId="quarters"
                  tickLabelStyle={{
                    fontFamily: 'Gotham',
                    fontSize: 14,
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                  }}
                />
                <ChartsReferenceLine y={100} lineStyle={{stroke: 'rgba(255,0,0,0.33)'}} />
                <ChartsReferenceLine y={0} lineStyle={{stroke: 'rgba(255,0,0,0.33)'}} />
                <React.Fragment>
                  {markerPositions
                    ?.filter((_, index) => data[index]?.measurement?.errors || data[index]?.originalValue < 0)
                    .map((item, _) => (
                      <image
                        x={item.x}
                        y={item.y + 10}
                        width={30}
                        height={30}
                        fill={'#FCBE68'}
                        href={'/static/images/svg/lightning-bolt-outline.svg'}
                      />
                    ))}
                  {markerPositions
                    ?.filter((_, index) => data[index]?.originalValue > 100)
                    .map((item, _) => (
                      <image
                        x={item.x}
                        y={item.y}
                        width={30}
                        height={30}
                        fill={'#FF5020'}
                        href={'/static/images/svg/out-of-bounds.svg'}
                      />
                    ))}
                  {markerPositions
                    ?.filter((_, index) => data[index]?.status !== 'NORMAL')
                    .map((item, _) => (
                      <image
                        x={item.x}
                        y={item.y}
                        width={30}
                        height={30}
                        fill={'#FF5020'}
                        href={'/static/images/svg/warning-status.svg'}
                      />
                    ))}
                  {markerPositions && currentMarker && (
                    <>
                      <circle
                        cx={currentMarker.x}
                        cy={currentMarker.y}
                        r={10}
                        fill={'transparent'}
                        stroke={'#FF5020'}
                        strokeWidth={2}
                        strokeDasharray={'4'}
                        width={100}
                        height={100}>
                        <animateTransform
                          attributeName="transform"
                          attributeType="xml"
                          type="rotate"
                          from={`0 ${currentMarker.x} ${currentMarker.y}`}
                          to={`360 ${currentMarker.x} ${currentMarker.y}`}
                          dur="7.5s"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <line
                        x1={currentMarker.x}
                        x2={currentMarker.x}
                        y1={0}
                        y2={430}
                        fill={'red'}
                        stroke={'#6C9DF2'}
                        strokeWidth={2}
                        strokeDasharray={'4,2'}
                      />
                    </>
                  )}
                  <MarkPlot
                    onItemClick={(_, i) => {
                      setCurrentIndex(i.dataIndex);
                      scrollToMarker(markerPositions[i.dataIndex]);
                    }}
                  />
                  <g
                    transform={`translate(${scrollLeft}, 0)`}
                    style={{transition: 'transform 0.3s ease-out, opacity 0.3s ease-out', opacity: axisOpacity}}>
                    <rect
                      style={{transition: 'transform 0.3s ease-out'}}
                      y={0}
                      fill={'rgba(255,255,255,0.83)'}
                      width={'70'}
                      height={'420'}
                    />
                    <ChartsLegend
                      labelStyle={{fontFamily: 'Gotham', fontSize: 14, opacity: 0.66}}
                      position={{vertical: 'bottom', horizontal: 'left'}}
                      itemMarkHeight={4}
                      itemGap={40}
                      padding={{top: -10, right: 10}}
                    />
                    <ChartsYAxis
                      axisId="quantities"
                      position="left"
                      tickLabelStyle={{
                        fontFamily: 'Gotham',
                        fontSize: 20,
                        fontVariantNumeric: 'tabular-nums',
                        userSelect: 'none',
                        WebkitUserSelect: 'none',
                      }}
                    />
                  </g>
                  {selection && (
                    <rect
                      x={selection.x}
                      y={20}
                      width={selection.width}
                      height={'410'}
                      fill={'rgba(108,157,202, 0.08)'}
                      stroke={'#6C9DF2'}
                      strokeWidth={1}
                      strokeDasharray={2}
                    />
                  )}
                </React.Fragment>
              </ChartContainer>
            )}
          </div>
        </Box>
      </div>
      {markerPositions && currentMarker && (
        <div className={'col-6 opacity-100 mt-3'}>
          <h5 className={'bold-heading'}>Data Point</h5>
          <JsonView
            style={{
              ...defaultStyles,
              label: 'fw-light text-primary font-monospace small',
              container: 'bg-white',
            }}
            data={datapoint}></JsonView>
        </div>
      )}
    </>
  );

  function renderErrors(errors: number[]) {
    const renderedErrors = errors.map(error => {
      return (
        <>
          <Icon className={'text-warning mx-1'} path={mdiLightningBoltOutline} size={1} />
          <span className={'me-1'}>
            {error === 0 && <span>UNKNOWN_ERROR</span>}
            {error === 1 && <span>NO_NETWORK_AVAILABLE</span>}
            {error === 2 && <span>TRANSMITTER_ERROR</span>}
            {error === 3 && <span>BACKEND_ERROR</span>}
            {error === 4 && <span>NOT_CALIBRATED</span>}
            {error === 5 && <span>CALM_WEIGHT_TIMEOUT_MEASURE</span>}
          </span>
          ({error})
        </>
      );
    });

    return (
      <div className={'small text-secondary'}>
        <span className={'ms-2'}>{renderedErrors}</span>
      </div>
    );
  }
};
