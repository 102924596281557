import {ZBaseDetails} from '../models/ZBaseApiModels';

const initialState: ZBaseDetails = {
  slug: null,
  assignment: {
    alias: '',
    pestCompany: undefined,
    clientCompany: undefined,
    section: undefined,
    createdAt: undefined,
    firstMeasurementReceivedAt: undefined,
  },
  lastMessage: null,
  location: null,
  deviceSettings: null,
  networkStatus: {
    signalStrength: 0,
    iccNumber: '',
    country: '',
    simStatus: {
      status: '',
      location: undefined,
      services: [],
    },
    simInfo: undefined,
    simQuota: undefined,
  },
  scaleConfiguration: null,
  status: null,
  totalLogs: 0,
  uuid: null,
  pastAssignments: null,
};

export default initialState;
