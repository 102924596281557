import React from 'react';
import {Alert} from 'react-bootstrap';
import {mdiAlertCircleOutline} from '@mdi/js';
import Icon from '@mdi/react';

interface Props {
  type?: 'warning' | 'info';
  message: string;
  icon?: string;
}

export const Warning: React.FC<Props> = ({type = 'info', message, icon = mdiAlertCircleOutline}) => {
  return (
    <Alert variant={type}>
      <Icon path={icon} size={1} className={'me-2'} />
      <span>{message}</span>
    </Alert>
  );
};
