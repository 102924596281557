import {createAsyncThunk} from '@reduxjs/toolkit';
import {exportCSV} from './api';

export const downloadExportAsync = createAsyncThunk(
  'pestCompanies/export/csv',
  async (params: {pestCompanyId: string; startDate?: string; endDate?: string}, {rejectWithValue}) => {
    try {
      return await exportCSV(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
