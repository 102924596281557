import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from './auth/slice';
import pestCompanyReducer from './pest-company/slice';
import inventoryReducer from './inventory/slice';
import tagsReducer from './tags/slice';
import scheduleMaintenanceReducer from './scheduled-maintenance/slice';

import zBaseReducer from '../features/zbases/api/slice';
import exportReducer from '../features/export/api/slice';

import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import {checkBoxReducer} from './checkBox/slice';
import baitboxLogsDetailsReducer from './baitbox-log-details/slice';

const persistConfig = {
  key: 'root',
  storage,
};

export const rootReducer = combineReducers({
  auth: authReducer,
  pestCompany: pestCompanyReducer,
  inventory: inventoryReducer,
  zBase: zBaseReducer,
  export: exportReducer,
  tags: tagsReducer,
  checkBox: checkBoxReducer,
  scheduleMaintenance: scheduleMaintenanceReducer,
  baitBoxLogDetails: baitboxLogsDetailsReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistorStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
