import React from 'react';
import {DetailPanel} from './shared/DetailPanel';
import {GaugeComponent} from 'react-gauge-component';
import {SimQuota} from '../models/ZBaseApiModels';
import {Warning} from './shared/Warning';
import Icon from '@mdi/react';
import {mdiSimOutline} from '@mdi/js';
import {StatusPanel} from './StatusPanel';

interface SimCardVolumePanelProps {
  simQuota: SimQuota | undefined;
}

export const SimCardVolumePanel: React.FC<SimCardVolumePanelProps> = ({simQuota}) => {
  const value = simQuota?.volume ?? 0;
  const maxValue = simQuota?.total_volume ?? 500;

  const remainingPercentage = simQuota ? (value / (simQuota?.total_volume ?? 1)) * 100 : '???';
  return (
    <DetailPanel heading={'SIM Card Data Volume'} className={'bg-primary-subtle'}>
      <div className={'d-flex flex-column'}>
        <div className={'justify-content-center'}>
          {!simQuota && <Warning message={'No data available.'} />}
          <>
            <div className={'pt-4 d-flex justify-content-center'}>
              <div>
                <StatusPanel
                  label={'Remaining'}
                  value={remainingPercentage}
                  thresholds={[{value: 95, type: 'below'}]}
                  unit={'%'}
                  icon={mdiSimOutline}
                />
              </div>
            </div>
            <GaugeComponent
              type={'grafana'}
              className={'pt-0 mt-0'}
              marginInPercent={{top: 0.05, bottom: 0.0, left: 0.1, right: 0.1}}
              style={{width: '72%', margin: '0px auto'}}
              maxValue={maxValue}
              minValue={0}
              value={value}
              labels={{
                valueLabel: {
                  style: {
                    fontFamily: 'Gotham',
                    fill: '#091D58',
                    textShadow: 'none',
                    fontWeight: 'bold',
                    fontSize: '50px',
                  },
                  formatTextValue: _ => formatValue(),
                },
                tickLabels: {
                  defaultTickValueConfig: {
                    style: {
                      fontFamily: 'Gotham',
                      fill: '#091D58',
                      textShadow: 'none',
                      fontWeight: 'bold',
                      fontSize: '14px',
                    },
                  },
                },
              }}
              arc={{
                colorArray: ['#C9DAF8', '#6C9DF2', '#091D58'],
                width: 0.35,
                emptyColor: 'transparent',
                subArcs: [
                  {
                    limit: 30,
                  },
                ],
              }}
            />
          </>
        </div>
        <div className={'position-absolute top-0 w-100 z-0'}>
          <div className={'text-primary d-flex justify-content-end'} style={{opacity: 0.02}}>
            <Icon path={mdiSimOutline} size={18} />
          </div>
        </div>
      </div>
    </DetailPanel>
  );

  function formatValue() {
    if (!simQuota) {
      return '--- MB';
    }
    return simQuota.volume.toFixed(2) + ' MB';
  }
};
