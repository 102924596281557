import {Box, Grid} from '@mui/material';
import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import AutoTextArea from 'components/UI/form/AutoTextArea';
import {Form, Formik} from 'formik';
import {HttpError} from 'models/http-interface';
import {BaitboxDeviceSettingsForm} from 'models/inventory.interface';
import {FC} from 'react';
import {useParams} from 'react-router';
import {getBaitCheckerState} from 'redux/inventory/selector';
import {updateBaitboxDeviceSettingsAsync} from 'redux/inventory/thunk';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {formatError} from 'utils/http-error-formatter';
import {showError, showSuccess} from 'utils/toast-alerts';
import {AddDeviceSettingsSchema} from 'utils/validation-schema';

const addDeviceSettings = [{name: 'deviceSettings', label: 'DEVICE_SETTINGS_RESPONSE', type: 'text'}];

type BaitboxDeviceSettingsProps = {
  setDeviceSettings: (deviceSettings: any) => void;
};
/**
 * @desc: baitbox device settings component
 * @returns
 */
const BaitboxDeviceSettings: FC<BaitboxDeviceSettingsProps> = ({setDeviceSettings}) => {
  const dispatch = useAppDispatch();
  const {id} = useParams();
  const {isUpdateBaitboxDeviceSettings} = useAppSelector(getBaitCheckerState);

  const initialValues: Partial<BaitboxDeviceSettingsForm> = {
    deviceSettings: '',
  };
  /**
   * @desc: submit baitbox device settings
   * @param values
   */
  const handleSubmit = async (values: any) => {
    try {
      /** to submit the device settings object */
      const response = await dispatch(
        updateBaitboxDeviceSettingsAsync({
          deviceSettings: values.deviceSettings,
          baitBoxIds: [id],
        }),
      ).unwrap();

      setDeviceSettings(JSON.parse(values.deviceSettings));

      showSuccess(response.message);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
    }
  };

  return (
    <>
      <div className="filter-search-container">
        <Formik
          initialValues={initialValues}
          validationSchema={AddDeviceSettingsSchema}
          onSubmit={(values, {resetForm}) => {
            handleSubmit(values);
            resetForm();
          }}
          validateOnBlur={true}
          validationOnChange={true}>
          {({isValid, dirty, resetForm}) => (
            <Form className="device-form p-0 mb-3">
              <Grid container spacing={2} className="device-setting-inner-form">
                {addDeviceSettings?.map(field => (
                  <div key={field.name} style={{width: '100%'}}>
                    <AutoTextArea name={field.name} label={field.label} customMessage={true} />
                  </div>
                ))}
              </Grid>

              <Box justifyContent={'space-between'} alignItems={'justify-end'}>
                <div className="btn-end">
                  <LoadingButton
                    type="submit"
                    label="SUBMIT"
                    loader={isUpdateBaitboxDeviceSettings}
                    className="loading-button"
                    disabled={!isValid || !dirty}
                  />

                  <Button variant={'secondary'} type="button" onClick={() => resetForm()} label="RESET" />
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BaitboxDeviceSettings;
