import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';
import {useAppDispatch, useAppSelector} from 'redux/redux';
import {getZBaseDetailsAsync, getZBaseRawLogsAsync} from '../api/thunk';
import {getZBaseDetailsState} from '../api/selector';
import {Loader} from 'react-feather';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {showSuccess} from 'utils/toast-alerts';
import 'react-json-view-lite/dist/index.css';
import {
  mdiAccountCircleOutline,
  mdiBattery,
  mdiBattery10,
  mdiBattery20,
  mdiBattery30,
  mdiBattery40,
  mdiBattery50,
  mdiBattery60,
  mdiBattery70,
  mdiBattery80,
  mdiBattery90,
  mdiBatteryOutline,
  mdiChevronRight,
  mdiContentCopy,
  mdiEmailHeartOutline,
  mdiIdentifier,
  mdiLandPlotsMarker,
  mdiOfficeBuildingMarkerOutline,
  mdiReorderVertical,
  mdiSignalCellular1,
  mdiSignalCellular2,
  mdiSignalCellular3,
  mdiSignalCellularOutline,
  mdiSignalOff,
  mdiTag,
  mdiThermometer,
  mdiWeightGram,
} from '@mdi/js';
import Icon from '@mdi/react';

import './ZBaseDetails.css';
import {StatusPanel} from './StatusPanel';
import useTranslate from '../../../hooks/useTranslate';
import {getStatusIcon} from '../shared/zBaseStatus';
import {DetailPanel} from './shared/DetailPanel';
import {SimCardVolumePanel} from './SimCardVolumePanel';
import {LastSeen} from './LastSeen';
import {ConnectivityPanel} from './ConnectivityPanel';
import {Chart} from './Chart';
import {allExpanded, defaultStyles, JsonView} from 'react-json-view-lite';
import BaitBoxLogs from '../../../pages/BaitBoxLogs';
import {ROUTES} from '../../../constants/routes';

const ZBaseDetails: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {parseUI} = useTranslate();

  const {isLoading, data, logs, isLoadingLogs} = useAppSelector(getZBaseDetailsState);

  useEffect(() => {
    if (id) {
      dispatch(getZBaseDetailsAsync({slug: id, uuid: id}));
      dispatch(getZBaseRawLogsAsync({slug: id, uuid: id}));
    }
  }, [id]);

  const statusIcon = getStatusIcon({status: data?.status?.scaleState});
  if (isLoading || isLoadingLogs) {
    return <Loader />;
  } else if (data === undefined || logs === undefined) {
    return (
      <div className={'container'}>
        <div className={'alert alert-danger'}>[z]Base not found.</div>
      </div>
    );
  }
  return (
    <div className={'container-fluid px-5 py-5'}>
      <div className={'row pb-4'}>
        <div className={'col-3 d-flex'}>
          <div className={'bg-white shadow-sm rounded-4 w-100 p-4 m-0'}>
            <div className={'d-flex justify-content-between'}>
              <div className={'bold-heading h1'}>{data.slug}</div>
            </div>
            <div className={'opacity-75 rounded-3 hoverable cur-pointer p-1'}>
              <Icon size={1} path={mdiTag} /> {data.assignment?.alias ?? 'Unassigned'}
            </div>
            {data.status && (
              <div className={'me-auto align-baseline'}>
                {statusIcon}
                <span className={'ms-1'}>{parseUI(data.status.scaleState)}</span>
              </div>
            )}
            <CopyToClipboard text={data.uuid} onCopy={() => showSuccess('UUID copied to clipboard')}>
              <div className={'opacity-75 rounded-3 hoverable cur-pointer p-1 font-monospace small'}>
                <Icon size={1} path={mdiIdentifier} className={'me-2'} />
                {data.uuid}
              </div>
            </CopyToClipboard>
          </div>
        </div>
        <div className={'col-9'}>
          <Card className={'rounded-4 overflow-scroll shadow-sm m-0'} style={{border: 'none'}}>
            <Card.Header className={'bg-white pt-3 px-4'} style={{border: 'none'}}>
              <div className="d-flex justify-content-between align-items-center">
                <div style={{width: '120px'}}>
                  <h2 className="bold-heading">Status</h2>
                </div>
                {data.assignment && (
                  <>
                    <div className="h6">
                      <div className={'mt-2'}>
                        <Icon size={0.9} path={mdiAccountCircleOutline} className="me-2" />
                        <a
                          onClick={() =>
                            navigate(
                              `/${ROUTES.DASHBOARD}/${ROUTES.VIEW_PEST_COMPANY}/${data.assignment?.pestCompany.id}`,
                            )
                          }
                          className={'opacity-75 cur-pointer text-decoration-none'}>
                          {data.assignment?.pestCompany?.name}
                        </a>
                      </div>
                    </div>

                    <div>
                      <Icon size={1.2} path={mdiChevronRight} style={{color: 'var(--Light-Blue-140)'}} />
                    </div>
                    <div className={'h6'}>
                      <div className={'mt-2'}>
                        <Icon size={0.9} path={mdiOfficeBuildingMarkerOutline} className={'me-2'} />
                        <span className={'opacity-75'}>{data.assignment?.clientCompany?.name}</span>
                      </div>
                    </div>
                    <div>
                      <Icon size={1.2} path={mdiChevronRight} style={{color: 'var(--Light-Blue-140)'}} />
                    </div>
                    <div className={'h6'} style={{flexGrow: 0.6}}>
                      <div className={'mt-2'}>
                        <Icon size={0.9} path={mdiLandPlotsMarker} className={'me-2'} />
                        <span className={'opacity-75'}>{data.assignment?.section?.name}</span>
                      </div>
                    </div>

                    <div>
                      <Icon size={1.2} path={mdiReorderVertical} style={{opacity: 0.15}} />
                    </div>
                  </>
                )}
                <LastSeen lastTransmissionDate={data.status?.lastTransmissionDate} />
              </div>
            </Card.Header>
            <Card.Body className={'p-2 pt-3'}>
              <div className={'d-flex justify-content-between w-100'}>
                <StatusPanel
                  label={'Weight'}
                  value={data.status?.weight}
                  unit={'%'}
                  icon={mdiWeightGram}
                  thresholds={[
                    {value: 120, type: 'above'},
                    {value: 80, type: 'below'},
                  ]}
                />
                <StatusPanel
                  label={'Battery'}
                  iconRotation={90}
                  value={data.status?.batteryLevel}
                  unit={'%'}
                  icon={mdiBatteryOutline}
                  thresholds={[{value: 75, type: 'below'}]}
                  thresholdIcons={[
                    {value: 0, icon: mdiBatteryOutline},
                    {value: 10, icon: mdiBattery10},
                    {value: 20, icon: mdiBattery20},
                    {value: 30, icon: mdiBattery30},
                    {value: 40, icon: mdiBattery40},
                    {value: 50, icon: mdiBattery50},
                    {value: 60, icon: mdiBattery60},
                    {value: 70, icon: mdiBattery70},
                    {value: 80, icon: mdiBattery80},
                    {value: 90, icon: mdiBattery90},
                    {value: 100, icon: mdiBattery},
                  ]}
                />
                <StatusPanel
                  label={'Temperature'}
                  decimals={1}
                  value={data.status?.temperature}
                  unit={'°'}
                  icon={mdiThermometer}
                />
                <StatusPanel
                  label={'Signal'}
                  value={data.networkStatus?.signalStrength}
                  unit={''}
                  thresholdIcons={[
                    {value: 0, icon: mdiSignalOff},
                    {value: 1, icon: mdiSignalCellularOutline},
                    {value: 2, icon: mdiSignalCellular1},
                    {value: 3, icon: mdiSignalCellular2},
                    {value: 4, icon: mdiSignalCellular3},
                    {value: 5, icon: mdiSignalCellular3},
                  ]}
                  thresholds={[{value: 1, type: 'below'}]}
                  icon={mdiSignalCellularOutline}
                />
                <StatusPanel label={'Messages'} value={data.totalLogs} unit={''} icon={mdiEmailHeartOutline} />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-5 col-md-9'}>
          <DetailPanel heading={'Bait Weight Time Series'}>{logs && <Chart data={logs.toReversed()} />}</DetailPanel>
          <BaitBoxLogs />
        </div>
        <div className={'col-md-3'}>
          <ConnectivityPanel
            simInfo={data.networkStatus.simInfo}
            simStatus={data.networkStatus.simStatus}
            iccNumber={data.networkStatus.iccNumber}
          />
          <SimCardVolumePanel simQuota={data.networkStatus.simQuota} />
          <DetailPanel heading={'Latest Message'} header={renderHeader()}>
            <Accordion className={'mb-3'}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Show Payload</Accordion.Header>
                <Accordion.Body>
                  <JsonView
                    data={data.lastMessage}
                    style={{
                      ...defaultStyles,
                      label: 'fw-light text-primary font-monospace',
                      container: 'bg-white',
                    }}
                    shouldExpandNode={allExpanded}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </DetailPanel>
        </div>
      </div>
    </div>
  );

  function renderHeader() {
    return (
      <CopyToClipboard
        text={JSON.stringify(data.lastMessage)}
        onCopy={() => showSuccess('Message copied to clipboard.')}>
        <Button variant={'secondary'}>
          <Icon size={0.8} path={mdiContentCopy} />
        </Button>
      </CopyToClipboard>
    );
  }
};

export default ZBaseDetails;
