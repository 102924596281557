import {createAsyncThunk} from '@reduxjs/toolkit';
import {getZBaseDetails, getZBaseRawLogs} from './api';
import {ZBaseDetailsRequestParams} from '../models/ZBaseApiModels';

export const getZBaseDetailsAsync = createAsyncThunk(
  'zbases/details',
  async (params: ZBaseDetailsRequestParams, {rejectWithValue}) => {
    try {
      const response = await getZBaseDetails(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getZBaseRawLogsAsync = createAsyncThunk(
  'zbases/details/logs',
  async (params: ZBaseDetailsRequestParams, {rejectWithValue}) => {
    try {
      const response = await getZBaseRawLogs(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
