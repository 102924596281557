import {CircularProgress} from '@mui/material';

import React, {FC} from 'react';

import useTranslate from 'hooks/useTranslate';
import Button from 'react-bootstrap/Button';

type com = {
  label: string;
  type: 'button' | 'submit';
  loader?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  color?: string;
};

const LoadingButton: FC<com> = ({label, type, loader, disabled, className, onClick, color}) => {
  const {parseUI} = useTranslate();
  return (
    <Button
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
      style={{backgroundColor: `${color}`}}>
      {parseUI(label)}
      {loader && (
        <CircularProgress
          style={{
            width: 15,
            height: 15,
            marginLeft: 10,
            color: '#fff',
          }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
