import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {showSuccess} from '../../../utils/toast-alerts';
import Icon from '@mdi/react';
import {
  mdiAlertOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiClockOutline,
  mdiCloudOffOutline,
  mdiCloudOutline,
  mdiCloudQuestionOutline,
  mdiCrossOutline,
  mdiLink,
} from '@mdi/js';
import i18next from 'i18next';
import {DetailPanel} from './shared/DetailPanel';
import {SimInfo, SimStatus} from '../models/ZBaseApiModels';
import {Alert} from 'react-bootstrap';
import {Warning} from './shared/Warning';

interface ConnectivityPanelProps {
  simInfo: SimInfo;
  simStatus: SimStatus;
  iccNumber: string;
}

export const ConnectivityPanel: React.FC<ConnectivityPanelProps> = ({iccNumber, simStatus, simInfo}) => {
  const {color, icon} = getSimStatusIcon();
  const location = simStatus?.location;
  return (
    <DetailPanel heading={'Connectivity'}>
      {!simStatus && (
        <Alert variant={'warning'}>
          <Icon path={mdiAlertOutline} size={1} /> There was an error retrieving data from 1nce.
        </Alert>
      )}
      <h5 className={'bold-heading'}>SIM Card Status</h5>
      <table className="table text-primary">
        <tbody>
          <tr>
            <th scope="row" className={'text-primary'}>
              ICCID
            </th>
            <td colSpan={2} className={'hoverable cur-pointer rounded-2 font-monospace'}>
              <CopyToClipboard text={iccNumber} onCopy={() => showSuccess('ICCID copied to clipboard')}>
                <div>{iccNumber}</div>
              </CopyToClipboard>
            </td>
          </tr>
          <tr>
            <th scope="row" className={'text-primary'}>
              Status
            </th>
            <td colSpan={2} className={color}>
              {icon && <Icon size={1} path={icon} />}
              <span className={'ps-2'}>{simInfo?.status ?? 'Unknown'}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" className={'text-primary'}>
              Location
            </th>
            <td colSpan={2}>
              {location?.country && (
                <img
                  src={`https://flagcdn.com/16x12/${location?.country.iso_code.toLowerCase()}.png`}
                  width="16"
                  height="12"
                  className={'me-2'}
                  alt={`${location?.country?.name}`}
                />
              )}
              {renderValueOrUnknown(simStatus?.location?.operator?.country.name)}
            </td>
          </tr>
          <tr>
            <th scope="row" className={'text-primary'}>
              Carrier
            </th>
            <td>{renderValueOrUnknown(simStatus?.location?.operator.name)}</td>
            {simStatus?.location?.operator.name && (
              <td className={'border-start d-flex justify-content-center'}>
                <img
                  src={`/static/images/${simStatus?.location?.operator.name.trimEnd().toLowerCase()}.png`}
                  alt={'No Carrier Logo'}
                  className={'p-2'}
                  style={{maxWidth: 100, maxHeight: 48}}
                  onError={e => {
                    e.currentTarget.style.display = 'none';
                  }}
                />
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">Enabled Services</th>
            <td colSpan={2}>{renderServices()}</td>
          </tr>
          <tr>
            <th scope="row">Last GRPS Update</th>
            <td colSpan={2}>{renderDate(simStatus?.location?.last_updated_gprs)}</td>
          </tr>
          <tr>
            <th scope="row">Activation Date</th>
            <td colSpan={2}>{renderDate(simInfo?.activation_date)}</td>
          </tr>
        </tbody>
      </table>
      <h5 className={'bold-heading'}>PDP Context</h5>
      {!simStatus?.pdp_context && <Warning message={'No active PDP context.'} />}
      <table className="table text-primary">
        <tbody>
          <tr>
            <th scope="row">Session Status</th>
            <td colSpan={2}>{renderSessionStatus()}</td>
          </tr>
          <tr>
            <th scope="row">Duration</th>
            <td>
              <Icon path={mdiClockOutline} size={1} className={'me-2'} />
              {simStatus?.pdp_context?.duration ?? '-'}
            </td>
          </tr>
          <tr>
            <th scope="row">Creation Date</th>
            <td>{renderDate(simStatus?.pdp_context?.created)}</td>
          </tr>
        </tbody>
      </table>
    </DetailPanel>
  );

  function getSimStatusIcon() {
    if (!simInfo?.status) {
      return {icon: mdiCloudQuestionOutline, color: 'text-secondary'};
    } else {
      if (simInfo.status === 'Enabled') return {icon: mdiCheckboxMarkedCircleOutline, color: 'text-success'};
      else return {icon: mdiCrossOutline, color: 'text-danger'};
    }
  }

  function renderValueOrUnknown(value?: any) {
    return !value ? renderUnknown() : value;
  }

  function renderServices() {
    if (!simStatus?.services) return renderUnknown();
    return (simStatus.services.length ? simStatus.services : ['-']).map(service => <div>{service}</div>);
  }

  function renderSessionStatus() {
    if (!simStatus?.status) return renderUnknown();
    const stateMap = {
      OFFLINE: {color: 'text-danger', icon: mdiCloudOffOutline},
      ONLINE: {icon: mdiCloudOutline, color: 'text-success'},
      ATTACHED: {icon: mdiLink, color: 'text-secondary'},
    };
    const {icon, color} = stateMap[simStatus.status];

    return (
      <div className={color}>
        <Icon size={1} path={icon} />
        <span className={'ps-2'}>{renderValueOrUnknown(simStatus?.status)}</span>
      </div>
    );
  }
};

export function renderUnknown() {
  return (
    <div className={'text-secondary'}>
      <Icon size={1} path={mdiCloudQuestionOutline} />
      <span className={'ps-2'}>{'Unknown'}</span>
    </div>
  );
}

export function renderDate(date: Date | string, options: Intl.DateTimeFormatOptions = {}) {
  if (date) return <div>{date.toLocaleString(i18next.language, options).replaceAll(',', '').replaceAll('.', '')}</div>;
  else return renderUnknown();
}
